<template>
  <div>
    <div style="padding-left:3.6em;margin-bottom:.5em">
      <div v-if="!hiddeTags&&[0,1,2,3,6].includes(hb.taskName)">
        编辑标签：
        <TagsInput
          style="margin:0 0 .5em;"
          ref="tagsEditor"
          :tags="hb.tagMap[/Verse/.test(hb.contentType)?0:currentIndex]"
        />
      </div>
      <div v-if="hb.taskName===4||hb.taskName===6">
        插画标签：
        <TagsInput
          style="margin:0 0 .5em;"
          ref="tagsEditor"
          :tags="hb.imageTag[/Verse/.test(hb.contentType)?0:currentIndex]"
        />
      </div>
      <div v-if="hb.taskName===5||hb.taskName===6">
        音频标签：
        <TagsInput
          style="margin:0 0 .5em;"
          ref="tagsEditor"
          :tags="hb.audioTag[/Verse/.test(hb.contentType)?0:currentIndex]"
        />
      </div>
      <div v-if="hasIntroduction">
        简介：
        <Input
          class="m-b-sm"
          type="textarea"
          placeholder="编辑文字简介（共用当前所有篇）"
          :rows="4"
          v-model="hb.introduction"
        />
      </div>

      <div style="width:38%;padding-bottom:1em">
        <Button type="primary" style="width:100%" @click="submitFn">提交</Button>
      </div>
    </div>
    <div class="wrapper">
      <HbPreview :hb="hb" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hb: {},
    hiddeTags: {}
  },
  data() {
    return {};
  },
  computed: {
    hasIntroduction() {
      return /^\w{2}(?:Idiom|Verse|View|Sentence)$/.test(this.hb.contentType);  //false; // 
    }
  },
  methods: {
    submitFn() {
      if (this.$refs.tagsEditor && this.$refs.tagsEditor.hasError) {
        return;
      }
      this.$emit('clickSubmit', this.hb);
    }
  },
  created() {
    this.currentIndex = 0;
  },
  mounted() {}
};
</script>

<style scoped>
.wrapper {
  width: 100%;
}
.sider {
  margin-right: 1.2em;
}
</style>